import { useMsal } from "@azure/msal-react";
import { faChainBroken, faExclamationCircle, faUserAlt, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Divider, Skeleton, Typography } from "@mui/material";
import { theme } from "@racwa/react-components";
import { GetMemberResponse } from "raci-testlaunchpage-clientproxy";
import { useMemberDetails } from "../MemberDetailsProvider";
import { useMyRacAccount } from "../MyRacAccountProvider";
import ProductCard from "../ProductCard";

export default function MemberProfile() {
  const [myRacAccount] = useMyRacAccount();
  const memberDetails = useMemberDetails();

  if (!myRacAccount.loggedIn) {
    // Splash screen is rendered
    return <></>;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: (theme) => theme.spacing(3),
        padding: (theme) => theme.spacing(4),
        backgroundColor: (theme) => theme.palette.secondary.light,
      }}
    >
      {!myRacAccount.crmId ? (
        <UnlinkedProfile loginEmail={myRacAccount.loginEmail} />
      ) : memberDetails.status === "loading" ? (
        <ProfileSkeleton />
      ) : memberDetails.status === "error" ? (
        <Error {...myRacAccount} />
      ) : (
        <Profile
          crmId={myRacAccount.crmId}
          loginEmail={myRacAccount.loginEmail}
          memberDetails={memberDetails.memberDetails}
        />
      )}
    </Box>
  );
}

function ProfileHeading({ heading, icon }: { heading: string; icon: IconDefinition }) {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: (theme) => theme.spacing(2) }}>
      <FontAwesomeIcon icon={icon} size="2x" color={theme.palette.secondary.dark} />
      <Typography variant="h1" sx={{ color: (theme) => theme.palette.primary.main }}>
        {heading}
      </Typography>
    </Box>
  );
}

function MemberDetails({
  loginEmail,
  crmId,
  racId,
  linkedShieldContactIds,
}: {
  loginEmail: string;
  crmId?: string;
  racId?: string;
  linkedShieldContactIds?: string[];
}) {
  const { instance } = useMsal();

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: (theme) => theme.spacing(2),
          padding: (theme) => theme.spacing(3),
          backgroundColor: (theme) => theme.palette.common.white,
          borderRadius: "4px",
        }}
      >
        <Box>
          <Typography variant="h3" fontSize={14}>
            Login Email
          </Typography>
          <Typography>{loginEmail}</Typography>
        </Box>
        {crmId && (
          <>
            <Divider />
            <Box>
              <Typography variant="h3" fontSize={14}>
                CRM ID
              </Typography>
              <Typography>{crmId}</Typography>
            </Box>
          </>
        )}
        {racId && (
          <>
            <Divider />
            <Box>
              <Typography variant="h3" fontSize={14}>
                RAC ID
              </Typography>
              <Typography>{racId}</Typography>
            </Box>
          </>
        )}
        {linkedShieldContactIds && (
          <>
            <Divider />
            <Box>
              <Typography variant="h3" fontSize={14}>
                Shield Contact IDs
              </Typography>
              <ul style={{ paddingLeft: "16px", margin: 0 }}>
                {linkedShieldContactIds.map((id) => (
                  <li key={id}>{id}</li>
                ))}
              </ul>
            </Box>
          </>
        )}
      </Box>
      <Button color="primary" onClick={() => instance.logoutRedirect()}>
        Logout
      </Button>
    </>
  );
}

function UnlinkedProfile({ loginEmail }: { loginEmail: string }) {
  return (
    <>
      <ProfileHeading heading="Unlinked Account" icon={faChainBroken} />
      <MemberDetails loginEmail={loginEmail} crmId="Not linked" />
    </>
  );
}

function ProfileSkeleton() {
  const width = 400;

  return (
    <>
      <Skeleton
        width={width}
        height={150}
        variant="rectangular"
        animation="wave"
        sx={{ borderRadius: (theme) => theme.spacing(1) }}
      />
      <Skeleton
        width={width}
        height={450}
        variant="rectangular"
        animation="wave"
        sx={{ backgroundColor: (theme) => theme.palette.common.white, borderRadius: (theme) => theme.spacing(1) }}
      />
    </>
  );
}

function Error({ loginEmail, crmId }: { loginEmail: string; crmId: string }) {
  return (
    <>
      <ProfileHeading heading="Error" icon={faExclamationCircle} />
      <MemberDetails loginEmail={loginEmail} crmId={crmId} />
    </>
  );
}

function Profile({
  crmId,
  loginEmail,
  memberDetails: { person, insurancePolicies, roadsideProducts },
}: {
  crmId: string;
  loginEmail: string;
  memberDetails: GetMemberResponse;
}) {
  return (
    <>
      <ProfileHeading heading={`${person.firstName} ${person.surname}`} icon={faUserAlt} />
      <MemberDetails
        loginEmail={loginEmail}
        crmId={crmId}
        racId={person.racId}
        linkedShieldContactIds={person.linkedShieldContactIds}
      />
      {insurancePolicies.length > 0 && (
        <>
          <Divider sx={{ backgroundColor: (theme) => theme.palette.secondary.dark }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: (theme) => theme.spacing(2),
            }}
          >
            {insurancePolicies.map(({ policyNumber, policyContactRole }) => (
              <ProductCard productNumber={policyNumber} subtitle={policyContactRole} />
            ))}
          </Box>
        </>
      )}
      {roadsideProducts.length > 0 && (
        <>
          <Divider sx={{ backgroundColor: (theme) => theme.palette.secondary.dark }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: (theme) => theme.spacing(2),
            }}
          >
            {roadsideProducts.map((productNumber) => (
              <ProductCard productNumber={productNumber} />
            ))}
          </Box>
        </>
      )}
    </>
  );
}
