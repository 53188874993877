import { Box, Button, Card, MenuItem, Select, TextField, Typography } from "@mui/material";
import { RacwaFormControl } from "@racwa/react-components";
import {
  FeatureToggle,
  HTTP_QUERYSTRING_TESTLAUNCHPAGE_FEATURE_TOGGLES,
  HTTP_QUERYSTRING_TESTLAUNCHPAGE_SHIELD_ENVIRONMENT,
} from "raci-react-library-npe";
import { useState } from "react";
import { useEnvironment } from "../EnvironmentProvider";
import { sparkApps } from "./constants";

export default function AppLauncher() {
  const { useMemberCentralMock, selectedShieldEnvironment } = useEnvironment();
  const [selectedApp, setSelectedApp] = useState(sparkApps[0]);
  const [queryParams, setQueryParams] = useState<Record<string, string>>(
    selectedApp.queryParameters.reduce((prev, curr) => {
      return { ...prev, [curr]: "" };
    }, {}),
  );

  const environmentQueryParams = {
    [HTTP_QUERYSTRING_TESTLAUNCHPAGE_SHIELD_ENVIRONMENT]: selectedShieldEnvironment,
    [HTTP_QUERYSTRING_TESTLAUNCHPAGE_FEATURE_TOGGLES]: window.btoa(
      JSON.stringify([
        { displayName: "Use MC Mock", headerName: "Feature_UseMCMock", enabled: useMemberCentralMock },
      ] satisfies FeatureToggle[]),
    ),
  };

  const queryString = `?${Object.entries({ ...queryParams, ...environmentQueryParams })
    .reduce<string[]>((prev, curr) => {
      const param = curr[0];
      const paramValue = curr[1];

      return paramValue ? [...prev, `${param}=${paramValue}`] : prev;
    }, [])
    .join("&")}`;

  const launchUrl = `${selectedApp.url}${queryString}`;

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        padding: (theme) => theme.spacing(8),
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(3), maxWidth: "600px", flex: 1 }}
      >
        <Typography variant="h2">Launch</Typography>
        <RacwaFormControl label="App">
          <Select
            value={selectedApp.name}
            onChange={(event) => {
              const selected = sparkApps.find((app) => app.name === event.target.value);
              if (selected) {
                setSelectedApp(selected);
              }
            }}
          >
            {sparkApps.map((app) => (
              <MenuItem key={app.name} value={app.name}>
                {app.name}
              </MenuItem>
            ))}
          </Select>
        </RacwaFormControl>
        <Card
          sx={{
            backgroundColor: (theme) => theme.palette.grey[200],
            padding: (theme) => theme.spacing(2),
          }}
        >
          <Typography variant="body2" sx={{ wordBreak: "break-all" }}>
            {launchUrl}
          </Typography>
        </Card>
        {selectedApp.queryParameters.map((param) => (
          <RacwaFormControl key={param} label={param}>
            <TextField
              sx={{ margin: 0 }}
              value={queryParams[`${param}`]}
              onChange={(event) => setQueryParams({ ...queryParams, [`${param}`]: event.target.value.trim() })}
            />
          </RacwaFormControl>
        ))}
        <Button color="primary" href={launchUrl} target="_blank" rel="noopener noreferrer">
          Launch
        </Button>
      </Box>
    </Box>
  );
}
