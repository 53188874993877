import { Typography } from "@mui/material";
import { Environment, useEnvironment } from "../EnvironmentProvider";

const colours = {
  LOCAL: "purple",
  DEV: "red",
  SIT: "green",
  UAT: "blue",
} satisfies Record<Environment["environment"], string>;

// TODO: add Use MC Mock checkbox
// TODO: add SHIELD environment selector

export default function TestBanner() {
  const { environment } = useEnvironment();

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: colours[`${environment}`],
      }}
    >
      <Typography variant="h1" color={(theme) => theme.palette.common.white}>
        {environment}
      </Typography>
    </div>
  );
}
