export const sparkApps = [
  {
    name: "Claims Motor Collision",
    url: "https://claimsmotorcollision-sit.racinsurance.ractest.com.au/claims/motor/collision",
    queryParameters: ["policyNumber"],
  },
  {
    name: "Claims Motor Glass",
    url: "https://claimsmotorglass-sit.racinsurance.ractest.com.au/claims/motor/glass",
    queryParameters: ["policyNumber"],
  },
] satisfies { name: string; url: string; queryParameters?: string[] }[];
