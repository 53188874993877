import { NpeEnvironment } from "raci-react-library-npe";

export const environment = (process.env.REACT_APP_ENVIRONMENT ?? "SIT") as NpeEnvironment;

type ShieldEnvironments = { memberCentralIntegrated: string; environments: string[] };

export const shieldEnvironments = {
  SIT: {
    memberCentralIntegrated: "SHIELDINT1",
    environments: ["SHIELDSIT1", "SHIELDSIT2", "SHIELDSIT3", "SHIELDSIT4", "SHIELDSIT13", "SAPIENSSIT1"],
  },
  UAT: {
    memberCentralIntegrated: "SHIELDUAT2",
    environments: ["SHIELDUAT1", "SHIELDUAT3", "SHIELDUAT4", "SHIELDUAT5"],
  },
} satisfies Record<Extract<NpeEnvironment, "SIT" | "UAT">, ShieldEnvironments>;
