import { NpeEnvironment } from "raci-react-library-npe";

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT as NpeEnvironment;

// <------------------>
//  ADB2C
// <------------------>
export const ADB2C_DOMAIN = process.env.REACT_APP_ADB2C_DOMAIN ?? "";
export const ADB2C_TENANT = process.env.REACT_APP_ADB2C_TENANT ?? "";
export const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID ?? "";
