import { useMsal } from "@azure/msal-react";
import { useAccount } from "@racwa/react-adb2c";
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { ADB2C_CLIENT_ID } from "../../constants";
import { TokenResponse } from "../../types";

export type MyRacAccount = { loggedIn: true; loginEmail: string; crmId: string } | { loggedIn: false };

const MyRacAccountContext = createContext<[MyRacAccount, (newCrmId: string) => Promise<void>] | null>(null);

const MAX_LOGIN_RETRIES = 10;

export default function MyRacAccountProvider({ children }: { children: ReactNode }) {
  const { instance } = useMsal();
  const account = useAccount({ clientId: ADB2C_CLIENT_ID });
  const [myRacAccount, setMyRacAccount] = useState<MyRacAccount>({ loggedIn: false });

  const loginWithNewCrmId = useCallback(
    async (newCrmId: string) => {
      try {
        let crmIdUpdated = false;
        let retries = 0;

        while (!crmIdUpdated && retries < MAX_LOGIN_RETRIES) {
          console.log("MyRacAccountProvider: loginWithNewCrmId", `ssoSilent retry ${retries + 1}`);

          const tokenResponse = (await instance.ssoSilent({ account, scopes: [], redirectUri: "/" })) as TokenResponse;

          console.log("MyRacAccountProvider: loginWithNewCrmId", { tokenResponse });

          const crmId = tokenResponse.idTokenClaims.extension_crmId ?? "";
          const loginEmail = tokenResponse.idTokenClaims.name;

          if (crmId === newCrmId) {
            console.log(
              "MyRacAccountProvider: loginWithNewCrmId",
              `CRM ID [${crmId}] returned from ssoSilent matches the new CRM ID [${newCrmId}]. Retries [${retries + 1}]`,
            );
            crmIdUpdated = true;
            setMyRacAccount({ loggedIn: true, loginEmail, crmId });
          } else {
            console.log(
              "MyRacAccountProvider: loginWithNewCrmId",
              `CRM ID [${crmId}] returned from ssoSilent does not match the new CRM ID [${newCrmId}]. Retries [${retries + 1}]`,
            );
          }

          retries += 1;
        }
      } catch (error) {
        console.log("MyRacAccountProvider: loginWithNewCrmId", { error });
        await instance.loginRedirect({ account, scopes: [], redirectUri: "/testlaunchpage/v2" });
      }
    },
    [account, instance],
  );

  useEffect(() => {
    const login = async () => {
      try {
        const tokenResponse = (await instance.ssoSilent({ account, scopes: [], redirectUri: "/" })) as TokenResponse;
        console.log("MyRacAccountProvider", { tokenResponse });

        const crmId = tokenResponse.idTokenClaims.extension_crmId ?? "";
        const loginEmail = tokenResponse.idTokenClaims.name;

        if (!crmId) {
          console.log("MyRacAccountProvider: account does not have a CRM ID");
          setMyRacAccount({ loggedIn: true, loginEmail, crmId: "" });
          return;
        }

        setMyRacAccount({ loggedIn: true, loginEmail, crmId });
      } catch (error) {
        console.log("MyRacAccountProvider", { error });
        await instance.loginRedirect({ account, scopes: [], redirectUri: "/testlaunchpage/v2" });
      }
    };

    login();
  }, [account, instance]);

  return (
    <MyRacAccountContext.Provider value={[myRacAccount, loginWithNewCrmId]}>{children}</MyRacAccountContext.Provider>
  );
}

export function useMyRacAccount() {
  const context = useContext(MyRacAccountContext);

  if (context === null) {
    throw new Error("useMyRacAccount must be used within a MyRacAccountProvider");
  }

  return context;
}
