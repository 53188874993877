import { faUserAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Card, Divider, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { RacwaFormControl, RacwaTextInput, theme } from "@racwa/react-components";
import { Selector, useBackdrop, useSetBackdrop } from "raci-react-library";
import { GetInsurancePolicyMembersResponse, Person } from "raci-testlaunchpage-clientproxy";
import { useEffect, useState } from "react";
import useApiClient from "../../hooks/useApiClient";
import { useEnvironment } from "../EnvironmentProvider";
import { useMemberDetails } from "../MemberDetailsProvider";
import { useMyRacAccount } from "../MyRacAccountProvider";

const switchOptions = ["CRM ID", "Policy Number"] as const;

export default function MemberSwitcher() {
  const apiClient = useApiClient();
  const [backdrop, setBackdrop] = useBackdrop();
  const [myRacAccount, loginWithNewCrmId] = useMyRacAccount();
  const memberDetails = useMemberDetails();
  const [switchOption, setSwitchOption] = useState<(typeof switchOptions)[number]>("CRM ID");

  if (!myRacAccount.loggedIn) {
    // Splash screen is rendered
    return <></>;
  }

  const disabled = !!(myRacAccount.crmId && !memberDetails) || backdrop.open;

  const switchMember = async (crmId: string) => {
    try {
      setBackdrop(true);
      await apiClient.switchMember({ email: myRacAccount.loginEmail, crmId });
      console.log("MemberSwitcher (switchMember):", `Switched member to CRM ID [${crmId}]`);
      await loginWithNewCrmId(crmId);
    } catch (error) {
      console.log("MemberSwitcher (switchMember):", { error });
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        padding: (theme) => theme.spacing(8),
      }}
    >
      <Box
        sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(3), maxWidth: "600px", flex: 1 }}
      >
        <Typography variant="h2">Switch Member</Typography>
        <RacwaFormControl label="With...">
          <ToggleButtonGroup value={switchOption} exclusive onChange={(e, value) => setSwitchOption(value)}>
            {switchOptions.map((opt) => (
              <ToggleButton value={opt}>{opt}</ToggleButton>
            ))}
          </ToggleButtonGroup>
        </RacwaFormControl>
        {switchOption === "CRM ID" ? (
          <CrmIdSwitcher switchMember={switchMember} disabled={disabled} />
        ) : (
          <PolicyNumberSwitcher switchMember={switchMember} disabled={disabled} />
        )}
      </Box>
    </Box>
  );
}

type SwitcherProps = {
  switchMember: (crmId: string) => Promise<void>;
  disabled: boolean;
};

function CrmIdSwitcher({ switchMember, disabled }: SwitcherProps) {
  const [newCrmId, setNewCrmId] = useState("");

  return (
    <>
      <RacwaTextInput label="CRM ID" value={newCrmId} onChange={(e) => setNewCrmId(e.target.value.trim())} />
      <Button color="primary" fullWidth disabled={disabled} onClick={() => switchMember(newCrmId)}>
        Switch
      </Button>
    </>
  );
}

function PolicyNumberSwitcher({ switchMember, disabled }: SwitcherProps) {
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const { useMemberCentralMock, selectedShieldEnvironment } = useEnvironment();
  const [myRacAccount] = useMyRacAccount();
  const [policyNumber, setPolicyNumber] = useState("");
  const [policyOwners, setPolicyOwners] = useState<GetInsurancePolicyMembersResponse | null>(null);

  useEffect(() => {
    setPolicyOwners(null);
  }, [useMemberCentralMock, selectedShieldEnvironment]);

  if (!myRacAccount.loggedIn) {
    // Splash screen is rendered
    return <></>;
  }

  const getPolicyOwners = async () => {
    try {
      setBackdrop(true);
      const owners = (await apiClient.getInsurancePolicyMembers(policyNumber)).result;
      console.log("PolicyNumberSwitcher (getPolicyOwners)", { owners });
      setPolicyOwners(owners);
    } catch (error) {
      console.error("PolicyNumberSwitcher (getPolicyOwners)", { error });
    } finally {
      setBackdrop(false);
    }
  };

  return (
    <>
      <RacwaTextInput
        label="Policy number"
        value={policyNumber}
        onChange={(e) => setPolicyNumber(e.target.value.trim())}
      />
      <Button color="primary" fullWidth disabled={disabled} onClick={getPolicyOwners}>
        Find Owners
      </Button>
      {policyOwners && (
        <Selector exclusive disabled={disabled} defaultValue={[myRacAccount.crmId]}>
          <PolicyOwnerCard person={policyOwners.owner} role="Owner" switchMember={switchMember} />
          {policyOwners.coOwners.map((coOwner) => (
            <PolicyOwnerCard person={coOwner} role="Co-owner" switchMember={switchMember} />
          ))}
        </Selector>
      )}
    </>
  );
}

function PolicyOwnerCard({
  person,
  role,
  switchMember,
}: {
  person: Person;
  role: "Owner" | "Co-owner";
  switchMember: SwitcherProps["switchMember"];
}) {
  const [myRacAccount] = useMyRacAccount();

  if (!myRacAccount.loggedIn) {
    // Splash screen is rendered
    return <></>;
  }

  const loggedInAsOwner = person.crmId === myRacAccount.crmId;

  return (
    <Card sx={{ display: "flex", gap: (theme) => theme.spacing(2), padding: (theme) => theme.spacing(2) }}>
      <Box sx={{ paddingTop: "6px" }}>
        <FontAwesomeIcon icon={faUserAlt} size="2x" color={theme.palette.secondary.light} />
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(2), flex: 1 }}>
        <Box>
          <Typography variant="h3">
            {person.firstName} {person.surname}
          </Typography>
          <Typography>{role}</Typography>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", flexDirection: "column", gap: (theme) => theme.spacing(2) }}>
          <Box>
            <Typography variant="h3" fontSize={14}>
              CRM ID
            </Typography>
            <Typography variant="body2">{person.crmId}</Typography>
          </Box>
          <Box>
            <Typography variant="h3" fontSize={14}>
              Shield Contact IDs
            </Typography>
            <ul style={{ paddingLeft: "16px", margin: 0 }}>
              {person.linkedShieldContactIds.map((id) => (
                <li key={id}>{id}</li>
              ))}
            </ul>
          </Box>
        </Box>
        <Button color="primary" disabled={loggedInAsOwner} onClick={() => switchMember(person.crmId)}>
          {loggedInAsOwner ? "Logged in" : "Switch"}
        </Button>
      </Box>
    </Card>
  );
}
