import { RacwaAuth } from "@racwa/react-adb2c";
import { RacwaThemeProvider } from "@racwa/react-components";
import { Backdrop } from "raci-react-library";
import { RecoilRoot } from "recoil";
import App from "./App";
import EnvironmentProvider from "./components/EnvironmentProvider";
import MemberDetailsProvider from "./components/MemberDetailsProvider";
import MyRacAccountProvider from "./components/MyRacAccountProvider";
import { ADB2C_CLIENT_ID, ADB2C_DOMAIN, ADB2C_TENANT } from "./constants";

// TODO: Handle errors from API and display appropriate error messages
// - Member not found when MemberDetailsProvider updating member
// - Failed to update CRM ID when switching with CRM ID
// - Failed to find Policy when getting policy owners
// - Failed to update CRM ID when switching with policy owner

const MyRacLaunchPage = () => {
  return (
    <RacwaAuth domain={ADB2C_DOMAIN} tenant={ADB2C_TENANT} clientId={ADB2C_CLIENT_ID} strict>
      <RacwaThemeProvider>
        <RecoilRoot>
          <EnvironmentProvider>
            <MyRacAccountProvider>
              <MemberDetailsProvider>
                <Backdrop />
                <App />
              </MemberDetailsProvider>
            </MyRacAccountProvider>
          </EnvironmentProvider>
        </RecoilRoot>
      </RacwaThemeProvider>
    </RacwaAuth>
  );
};

export default MyRacLaunchPage;
