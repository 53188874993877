import { faPeopleArrows, faRocket } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Fab } from "@mui/material";
import { RacwaSplashScreen } from "@racwa/react-components";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "./components/Header";
import MemberProfile from "./components/MemberProfile";
import { useMyRacAccount } from "./components/MyRacAccountProvider";
import TestBanner from "./components/TestBanner";

export default function App() {
  const location = useLocation();
  const navigate = useNavigate();
  const [myRacAccount] = useMyRacAccount();

  if (!myRacAccount.loggedIn) {
    return <RacwaSplashScreen />;
  }

  const isLaunchPage = location.pathname.includes("launch");

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <TestBanner />
      <Header />
      <Box sx={{ display: "flex", flex: 1 }}>
        <MemberProfile />
        <Outlet />
        <Fab
          sx={{
            position: "fixed",
            bottom: 32,
            right: 32,
            backgroundColor: (theme) => theme.palette.primary.dark,
            padding: (theme) => theme.spacing(4),
            "&:hover": {
              backgroundColor: (theme) => theme.palette.secondary.dark,
              "& > svg": {
                color: (theme) => theme.palette.common.white,
              },
            },
          }}
          onClick={() => navigate(isLaunchPage ? "/v2" : "/v2/launch")}
        >
          <FontAwesomeIcon size="2x" icon={isLaunchPage ? faPeopleArrows : faRocket} />
        </Fab>
      </Box>
    </Box>
  );
}
