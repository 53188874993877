import { useSetBackdrop } from "raci-react-library";
import { GetMemberResponse } from "raci-testlaunchpage-clientproxy";
import { createContext, ReactNode, useContext, useEffect, useState } from "react";
import useApiClient from "../../hooks/useApiClient";
import { useMyRacAccount } from "../MyRacAccountProvider";

type MemberDetails =
  | { status: "loading" }
  | { status: "error" }
  | { status: "found"; memberDetails: GetMemberResponse };

const MemberDetailsContext = createContext<MemberDetails | null>(null);

export default function MemberDetailsProvider({ children }: { children: ReactNode }) {
  const apiClient = useApiClient();
  const setBackdrop = useSetBackdrop();
  const [myRacAccount] = useMyRacAccount();
  const [memberDetails, setMemberDetails] = useState<MemberDetails>({ status: "loading" });

  useEffect(() => {
    const getMember = async (crmId: string) => {
      try {
        setBackdrop(true);
        const memberDetails = (await apiClient.getMember(crmId)).result;
        console.log("MemberDetailsProvider", { memberDetails });
        setMemberDetails({ status: "found", memberDetails });
      } catch (error) {
        console.log("MemberDetailsProvider", { error });
        setMemberDetails({ status: "error" });
      } finally {
        setBackdrop(false);
      }
    };

    if (myRacAccount.loggedIn) {
      getMember(myRacAccount.crmId);
    }
  }, [apiClient, myRacAccount, myRacAccount.loggedIn, setBackdrop]);

  return <MemberDetailsContext.Provider value={memberDetails}>{children}</MemberDetailsContext.Provider>;
}

export function useMemberDetails() {
  const context = useContext(MemberDetailsContext);

  if (context === null) {
    throw new Error("useMemberDetails must be used within a MemberDetailsProvider");
  }

  return context;
}
