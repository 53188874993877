import { createBrowserRouter, RouterProvider } from "react-router-dom";
import TestLaunchPage from "./views/LaunchPage";
import MyRacLaunchPage from "./views/MyRacLaunchPage";
import AppLauncher from "./views/MyRacLaunchPage/components/AppLauncher";
import MemberSwitcher from "./views/MyRacLaunchPage/components/MemberSwitcher";

const router = createBrowserRouter(
  [
    { path: "/", element: <TestLaunchPage /> },
    {
      path: "/v2",
      element: <MyRacLaunchPage />,
      children: [
        { path: "/v2", element: <MemberSwitcher /> },
        { path: "/v2/launch", element: <AppLauncher /> },
      ],
    },
  ],
  { basename: "/testlaunchpage" },
);

export const App = () => <RouterProvider router={router} />;

export default App;
