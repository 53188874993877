import { Box, Card, Typography } from "@mui/material";
import {
  BoatGRFX019,
  CaravanGRFX031,
  CarGRFX001,
  ElectricMobilityGRFX038,
  HomeGRFX021,
  MotoGRFX016NoPadding,
  PetsGRFX206NoPadding,
  RoadsideVehicle,
} from "@racwa/react-components";
import { PolicyNumberPrefix } from "raci-react-library";

export default function ProductCard({ productNumber, subtitle }: { productNumber: string; subtitle?: string }) {
  return (
    <Card
      sx={{
        display: "flex",
        alignItems: "center",
        gap: (theme) => theme.spacing(2),
        padding: (theme) => theme.spacing(1),
      }}
    >
      <Box sx={{ display: "flex", width: "64px", height: "64px", "& > svg": { flex: 1, height: "100%" } }}>
        {getProductTypeIcon(productNumber)}
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography variant="h3">{productNumber}</Typography>
        {subtitle && <Typography variant="body2">{subtitle}</Typography>}
      </Box>
    </Card>
  );
}

function getProductTypeIcon(productNumber: string) {
  const productType = productNumber.substring(0, 3);

  switch (productType) {
    case PolicyNumberPrefix.Boat:
      return <BoatGRFX019 />;
    case PolicyNumberPrefix.CaravanTrailer:
      return <CaravanGRFX031 />;
    case PolicyNumberPrefix.ElectricMobility:
      return <ElectricMobilityGRFX038 />;
    case PolicyNumberPrefix.Home:
      return <HomeGRFX021 />;
    case PolicyNumberPrefix.Motor:
      return <CarGRFX001 />;
    case PolicyNumberPrefix.Motorcycle:
      return <MotoGRFX016NoPadding />;
    case PolicyNumberPrefix.Pet:
      return <PetsGRFX206NoPadding />;
    case "RSA":
      return <RoadsideVehicle />;
    default:
      return <></>;
  }
}
