import { NpeEnvironment } from "raci-react-library-npe";
import { createContext, ReactNode, useContext, useState } from "react";
import { environment, shieldEnvironments } from "./constants";

const shield = shieldEnvironments[environment === "UAT" ? "UAT" : "SIT"];

export type Environment = {
  environment: NpeEnvironment;
  shieldEnvironments: string[];
  useMemberCentralMock: boolean;
  setUseMemberCentralMock: (value: boolean) => void;
  selectedShieldEnvironment: string;
  setSelectedShieldEnvironment: (value: string) => void;
};

const EnvironmentContext = createContext<Environment | null>(null);

export default function EnvironmentProvider({ children }: { children: ReactNode }) {
  const [useMemberCentralMock, setUseMemberCentralMock] = useState<boolean>(false);
  const [selectedShieldEnvironment, setSelectedShieldEnvironment] = useState<string>(shield.memberCentralIntegrated);

  return (
    <EnvironmentContext.Provider
      value={{
        environment,
        shieldEnvironments: useMemberCentralMock ? shield.environments : [shield.memberCentralIntegrated],
        useMemberCentralMock,
        setUseMemberCentralMock: (value: boolean) => {
          setUseMemberCentralMock(value);

          if (!value) {
            setSelectedShieldEnvironment(shield.memberCentralIntegrated);
          } else {
            setSelectedShieldEnvironment(shield.environments[0]);
          }
        },
        selectedShieldEnvironment,
        setSelectedShieldEnvironment,
      }}
    >
      {children}
    </EnvironmentContext.Provider>
  );
}

export function useEnvironment() {
  const context = useContext(EnvironmentContext);

  if (context === null) {
    throw new Error("useEnvironment must be used within a EnvironmentProvider");
  }

  return context;
}
