import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { RacwaThemeProvider } from "@racwa/react-components";
import { Backdrop } from "raci-react-library";
import { FeatureToggleProvider, ShieldEnvironmentProvider } from "raci-react-library-npe";
import { RecoilRoot } from "recoil";
import { reactPlugin } from "../../shared/AppInsights/reactPlugin";
import { FEATURE_TOGGLES, SHIELD_ENVIRONMENTS } from "../../shared/constants";
import TestLaunchPageForm from "./Form";

export const TestLaunchPage = () => {
  return (
    <RacwaThemeProvider>
      <RecoilRoot>
        <AppInsightsContext.Provider value={reactPlugin}>
          <Backdrop />
          <FeatureToggleProvider featureToggles={FEATURE_TOGGLES}>
            <ShieldEnvironmentProvider environments={SHIELD_ENVIRONMENTS}>
              <TestLaunchPageForm />
            </ShieldEnvironmentProvider>
          </FeatureToggleProvider>
        </AppInsightsContext.Provider>
      </RecoilRoot>
    </RacwaThemeProvider>
  );
};

export default TestLaunchPage;
