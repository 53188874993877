import { Box, FormControlLabel, MenuItem, Select, Switch, switchClasses, Typography } from "@mui/material";
import { theme } from "@racwa/react-components";
import { useEnvironment } from "../EnvironmentProvider";

export default function Header() {
  const {
    useMemberCentralMock,
    setUseMemberCentralMock,
    shieldEnvironments,
    selectedShieldEnvironment,
    setSelectedShieldEnvironment,
  } = useEnvironment();

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: (theme) => theme.palette.secondary.dark,
        borderBottom: `4px solid ${theme.palette.primary.main}`,
        padding: (theme) => theme.spacing(1),
        paddingLeft: (theme) => theme.spacing(2),
        paddingRight: (theme) => theme.spacing(2),
      }}
    >
      <Box sx={{ display: "flex", flex: "1", alignItems: "center" }}>
        <Typography sx={{ color: (theme) => theme.palette.grey["500"], fontSize: "14px", fontWeight: 400 }}>
          / Test Launch Page 2.0
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flex: "1",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: (theme) => theme.spacing(2),
        }}
      >
        <FormControlLabel
          control={
            <Switch
              color="primary"
              checked={useMemberCentralMock}
              onChange={(_, value) => {
                setUseMemberCentralMock(value);
              }}
              sx={{ [`.${switchClasses.track}`]: { backgroundColor: (theme) => theme.palette.grey[500] } }}
            />
          }
          label="Use MC Mock"
          labelPlacement="start"
          sx={{ color: (theme) => theme.palette.common.white }}
        />
        <Select
          disabled={!useMemberCentralMock}
          value={selectedShieldEnvironment}
          onChange={(event) => setSelectedShieldEnvironment(event.target.value)}
        >
          {shieldEnvironments.map((env) => (
            <MenuItem key={env} value={env}>
              {env}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Box>
  );
}
