import { useAccount } from "@racwa/react-adb2c";
import {
  createMsalClient,
  HTTP_HEADER_KEY_RACWA_NPE_AUTHORIZATION,
  HTTP_HEADER_KEY_SHIELD_ENVIRONMENT,
} from "raci-react-library-npe";
import { TestLaunchPageApiClient } from "raci-testlaunchpage-clientproxy";
import { useMemo } from "react";
import { OAUTH_AUTHORITY, OAUTH_CLIENT_ID } from "../../../shared/constants";
import { enableInternalAuth } from "../../../shared/EnableInternalAuth";
import { useEnvironment } from "../components/EnvironmentProvider";

export const useApiClient = (): TestLaunchPageApiClient => {
  const npeInstance = enableInternalAuth
    ? createMsalClient(OAUTH_CLIENT_ID, OAUTH_AUTHORITY, window.location.origin)
    : null;
  const npeAccount = useAccount({ clientId: OAUTH_CLIENT_ID });
  const { useMemberCentralMock, selectedShieldEnvironment } = useEnvironment();

  return useMemo(() => {
    const fetchWrapper = async (url: RequestInfo, init?: RequestInit): Promise<Response> => {
      let npeToken = "";

      if (npeInstance) {
        const loginRequestBase = {
          scopes: [],
          redirectUri: "/",
        };

        const npeLoginRequest = {
          ...loginRequestBase,
          account: npeAccount,
        };

        try {
          const tokenResponse = await npeInstance.acquireTokenSilent(npeLoginRequest);
          npeToken = tokenResponse.idToken;
        } catch (ex) {
          await npeInstance.acquireTokenRedirect(npeLoginRequest);
        }
      }

      const headers: any = { ...init?.headers };

      headers[`Feature_UseMCMock`] = useMemberCentralMock;
      headers[`${HTTP_HEADER_KEY_SHIELD_ENVIRONMENT}`] = selectedShieldEnvironment;

      if (npeToken) {
        headers[`${HTTP_HEADER_KEY_RACWA_NPE_AUTHORIZATION}`] = npeToken;
      }

      const response = await fetch(url, {
        ...init,
        headers,
      });
      return response;
    };

    return new TestLaunchPageApiClient(process.env.REACT_APP_API_BASE_URL || "", {
      fetch: fetchWrapper,
    });
  }, [npeAccount, npeInstance, selectedShieldEnvironment, useMemberCentralMock]);
};

export default useApiClient;
